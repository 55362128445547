import { Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { LocalShipping } from '@mui/icons-material'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import 'moment/locale/pl'
import React from 'react'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useTranslation } from 'next-i18next'
import DescriptionIcon from '@mui/icons-material/Description'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        // cursor: "pointer",
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        cursor: 'pointer',
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      },

      chevronRight: {
        cursor: 'pointer',
      },

      carIcon: {
        fontSize: '30px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
    }),
  { name: 'MuiInfoLinkBox' },
)

const InfoLinkBox = ({ title, link, icon = null }) => {
  const classes = useStyles() as any

  const Icon = icon ? icon : DescriptionIcon

  if (title.endsWith(':')) {
    title = title.slice(0, -1)
  }

  return (
    <>
      <div
        className={classes.row}
        onClick={() => {
          window.open(link, '_blank')
        }}
      >
        <div className={classes.rowContentContainer}>
          <Icon className={classes.carIcon} />

          <Typography
            component="p"
            variant="body1"
            style={{ marginRight: '5px', paddingTop: '3px' }}
          >
            {title}
          </Typography>
        </div>

        <ChevronRightIcon className={classes.chevronRight} />
      </div>
      <Divider light />
    </>
  )
}

export default InfoLinkBox
