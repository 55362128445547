// lib/myFunction.js
import { gql } from '@apollo/client'
import { CartItem } from '../src/store/models'
import { cartItemsVar } from '../src/store/reactiveVars'
import { initializeApollo } from './apolloClient'
// import client from '../path-to-apollo-client'; // Import your Apollo Client instance

const client = initializeApollo()

//=== SAVING USER BROWSING HISTORY =======
const SAVE_USER_PATH_MUTATION = gql`
  mutation saveUserPath(
    $cookie: String!
    $absolutePath: String!
    $locale: Language!
    $defaultLocale: Language!
  ) {
    saveUserPath(
      cookie: $cookie
      absolutePath: $absolutePath
      locale: $locale
      defaultLocale: $defaultLocale
    )
  }
`
export async function routeSaveMutation(cookie, routerData) {
  try {
    // console.log('In route save')
    // console.log('routerData :>> ', routerData)

    if (routerData) {
      const locale = routerData.locale
      const defaultLocale = routerData.defaultLocale
      const absolutePath = routerData.asPath
      // === FUTURE (Maybe) ======
      //   const productPermalink = routerData?.query?.product
      //   const categoryPermalink = routerData?.query?.slug
      //     ? routerData?.query?.slug?.length
      //       ? routerData?.query?.slug[0]
      //       : undefined
      //     : undefined
      // // ==== Doesnt really work as there are nore wirds they are stored as more items in the array ['red', 'chair']
      //   const searchPermalink = routerData?.query?.search_query
      //     ? routerData?.query?.search_query?.length
      //       ? routerData?.query?.search_query[0]
      //       : undefined
      //     : undefined

      //   console.log('absolutePath :>> ', absolutePath)
      //   console.log('locale :>> ', locale)

      const response = await client.mutate({
        mutation: SAVE_USER_PATH_MUTATION,
        variables: {
          cookie,
          locale,
          absolutePath,
          defaultLocale,
        },
      })
    }

    // // You can handle the response here and return any relevant data
    // return response.data;
  } catch (error) {
    // Handle errors here
    console.error('Error invoking mutation:', error)
    throw error
  }
}

//=== SAVING USER CART ITEMS =======
const SAVE_USER_CART_ITEM_MUTATION = gql`
  mutation saveUserCartItem(
    $cookie: String!
    $cartItem: String!
    $removed: Boolean!
  ) {
    saveUserCartItem(cookie: $cookie, cartItem: $cartItem, removed: $removed)
  }
`

export async function cartItemSaveMutation(cookie, cartItem, removed = false) {
  try {
    // console.log('In route save')
    // console.log('cartItem :>> ', cartItem)

    if (cartItem) {
      const cartItemString = JSON.stringify(cartItem)

      const response = await client.mutate({
        mutation: SAVE_USER_CART_ITEM_MUTATION,
        variables: {
          cookie,
          cartItem: cartItemString,
          removed,
        },
      })
    }

    // // You can handle the response here and return any relevant data
    // return response.data;
  } catch (error) {
    // Handle errors here
    console.error('Error invoking mutation:', error)
    throw error
  }
}

//=== SAVING USER WISHLIST ITEMS =======
const SAVE_USER_WISHLIST_ITEM_MUTATION = gql`
  mutation saveUserWishListItem(
    $cookie: String!
    $wishListItem: String!
    $removed: Boolean!
  ) {
    saveUserWishListItem(
      cookie: $cookie
      wishListItem: $wishListItem
      removed: $removed
    )
  }
`

export async function wishListItemSaveMutation(
  cookie,
  wishListItem,
  removed = false,
) {
  try {
    // console.log('In route save')
    // console.log('wishListItem :>> ', wishListItem)

    if (wishListItem) {
      const wishListItemString = JSON.stringify(wishListItem)

      const response = await client.mutate({
        mutation: SAVE_USER_WISHLIST_ITEM_MUTATION,
        variables: {
          cookie,
          wishListItem: wishListItemString,
          removed,
        },
      })
    }

    // // You can handle the response here and return any relevant data
    // return response.data;
  } catch (error) {
    // Handle errors here
    console.error('Error invoking mutation:', error)
    throw error
  }
}

//=== RETRIEVING USER CART ITEMS =======
const RETRIEVE_USER_CART_ITEMS = gql`
  query retrieveUserCartItems($cookie: String!) {
    retrieveUserCartItems(cookie: $cookie)
  }
`

export async function retrieveUserCartItems(cookie) {
  try {
    // console.log('In retrieve user cartItems')
    const response = await client.query({
      query: RETRIEVE_USER_CART_ITEMS,
      variables: {
        cookie,
      },
      fetchPolicy: 'network-only',
    })


    let serverCartItemsFormated = [] as any
    if (response && response?.data) {
      //   console.log('response :>> ', response)

      const data = response.data.retrieveUserCartItems
      const cartIemsList = JSON.parse(data)
      //   console.log('cartIemsList :>> ', cartIemsList)

      if (cartIemsList) {
        for (const serverCartItem of cartIemsList) {
          if (!serverCartItem.removed) {
            //-- Caritem was not removed so we can safely add them.
            const serverCartItemParsed = JSON.parse(serverCartItem.data)
            serverCartItemsFormated.push(serverCartItemParsed)
          }
        }
      }
    }

    //--- PUSH TO CART
    if (serverCartItemsFormated.length > 0) {
      //-- means there are server cartiems
      //   console.log('serverCartItemsFormated :>> ', serverCartItemsFormated)
      //--- Maybe we need to somehow deal with latency issues server cart items were wrongly updated...
      const cartItems: CartItem[] = cartItemsVar()
      cartItemsVar([...serverCartItemsFormated])
    }

    // // You can handle the response here and return any relevant data
    // return response.data;
  } catch (error) {
    // Handle errors here
    console.error('Error invoking mutation:', error)
    throw error
  }
}
