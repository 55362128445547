import { Box, Divider, Grid, Typography } from '@mui/material'
import Konva from 'konva'
import { useRef } from 'react'
import { makeStyles } from '@mui/styles'
import PreviewSelectedFabric from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedFabric'
import DimensionsBlock from '../ProductPageElements/AdvancedProductElements/Components/DimensionsBlock'
import PreviewSelectedAdditionalComponent from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedAdditionalComponent'
import { useTranslation } from 'react-i18next'
import {
  ChairDimensionsDrawing,
  BedDimensionsDrawing,
  TableDimensionsDrawing,
} from '../ProductPageElements/AdvancedProductElements/Components/ConfigurationBlock'
import { getComponentGroupName } from '../../../utilityFunctions/getComponentGroupName'
import { useAppContext } from '../../../context/appContext'

const useStylesCombinationPreview = makeStyles(
  (theme) => ({
    root: {
      padding: '10px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },

    sofaDrawingBox: {
      minHeight: '300px',
      maxHeight: '900px',
      // height: '300px',
      borderLeft: '1px solid #e2e1e0',

      pointerEvents: 'none',
      // touchAction: 'none',

      [theme.breakpoints.down('lg')]: {
        borderLeft: 'none',
        borderTop: '1px solid #e2e1e0',
      },
    },

    contentBox: {
      padding: '10px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    drawingBox: {
      //   borderLeft: '1px solid grey',
      width: '100%',
      //   height: '200px',
    },
  }),
  { name: 'MuiChairCartPagePreviewomponent' },
)

const AdvancedProductCartPagePreview = ({
  selectedFabric,
  selectedAddtionalComponents,
  discount = null,
  dimensions,
  advancedProductType,
  ...props
}) => {
  const classes = useStylesCombinationPreview() as any
  const { t, i18n } = useTranslation(['common'])
  const specifictionText = t('specification-text')
  const appContext = useAppContext()
  const locale = appContext ? appContext.locale : 'en'

  const currentDimensions = dimensions
  const dimensionNames = Object.keys(currentDimensions)

  return (
    <>
      <Divider />
      <div className={classes.root}>
        <Grid container justifyContent="space-evenly" spacing={0}>
          <Grid item xs={12} md={6}>
            <Box className={classes.contentBox}>
              <Box className={classes.dimensionsBox}>
                <DimensionsBlock dimensions={currentDimensions} />
              </Box>

              {/* CHAIR DIMENSION PARTS */}
              <Box
                sx={{
                  padding: '10px 0',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {dimensionNames.map((dimensionName, i) => (
                  <div key={i}>
                    {advancedProductType === 'CHAIR_ARMCHAIR' && (
                      <ChairDimensionsDrawing
                        dimensionName={dimensionName}
                        dimensions={currentDimensions}
                      />
                    )}

                    {advancedProductType === 'BED' && (
                      <BedDimensionsDrawing
                        dimensionName={dimensionName}
                        dimensions={currentDimensions}
                      />
                    )}

                    {advancedProductType === 'TABLE' && (
                      <TableDimensionsDrawing
                        dimensionName={dimensionName}
                        dimensions={currentDimensions}
                      />
                    )}
                  </div>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.sofaDrawingBox}>
            <Box className={classes.drawingBox}>
              <Box className={classes.specification}>
                <Typography
                  variant="h6"
                  component="p"
                  style={{ marginRight: '100px' }}
                >
                  {specifictionText}
                </Typography>
              </Box>
              {selectedFabric.fabricObject && (
                <PreviewSelectedFabric
                  selectedAdvancedProductFabric={selectedFabric}
                />
              )}
              {selectedAddtionalComponents.map((component, i) => (
                <PreviewSelectedAdditionalComponent
                  key={component.id}
                  selectedAdditionalComponent={component}
                  discount={discount}
                  selectedFabric={selectedFabric}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AdvancedProductCartPagePreview
