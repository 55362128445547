import { Box, Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { LocalShipping } from '@mui/icons-material'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import 'moment/locale/pl'
import React from 'react'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useTranslation } from 'next-i18next'

import * as countryFlagIcons from 'country-flag-icons/react/3x2'
import { getCountryName } from '../../../utilityFunctions/getCountryName'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        // cursor: 'none',
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      },

      // chevronRight: {
      //   cursor: 'pointer',
      // },

      carIcon: {
        fontSize: '30px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
    }),
  { name: 'MuiCountryOfOriginBoxBox' },
)

const CountryOfOriginBox = ({ countryCode, locale }) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const country_of_origin_string = t('country_of_origin_string')

  // console.log("shipping_method ->", shipping_method);
  // console.log('countryCode :>> ', countryCode)
  // console.log('locale :>> ', locale)

  const FlagComponent = countryFlagIcons[countryCode]
  const countryName = getCountryName(countryCode, locale)

  return (
    <>
      <div className={classes.row}>
        <Typography
          component="p"
          variant="body1"
          style={{ marginRight: '5px' }}
        >
          {country_of_origin_string}:
        </Typography>

        <Typography component="p" variant="body2">
          {countryName}
        </Typography>

        <Box sx={{ width: '25px', height: '25px', marginLeft: '10px' }}>
          <FlagComponent />
        </Box>

        {/* <ChevronRightIcon className={classes.chevronRight} /> */}
      </div>
      <Divider light />
    </>
  )
}

export default CountryOfOriginBox
