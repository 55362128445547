import { useReactiveVar } from '@apollo/client'
import { Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import {
  componentsDrawerStateVar,
  fabricsDrawerStateVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import AdditionalComponentDrawerCard from './AdditionalComponentDrawerCard'
import FabricDrawerCard from './DrawerCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      fabricsDrawer: {
        width: 700,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topDrawerDescription: {
        margin: theme.spacing(0, 0, 3, 0),
      },
      topDrawerContent: {
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        marginBottom: theme.spacing(2),
      },

      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiFabricsDrawerComponent' },
)

export default function FabricsDrawer() {
  const { t } = useTranslation(['common'])
  const classes = useStyles() as any
  const fabricsDrawerState = useReactiveVar(fabricsDrawerStateVar)
  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )
  const { locale } = useAppContext()

  const optionInSelect = fabricsDrawerState?.option?.id
  const selectedFabricId =
    selectedAdvancedProductFabric?.combinationFabrics?.[optionInSelect]
      ?.fabricObject?.id

  const profile = getProfileFromLanguage(
    fabricsDrawerState.option?.fabricCombinationOptionProfiles,
    locale,
  )

  const selectorTitle = profile?.name ?? ''

  const onSelectedComponentChange = (fabric, fabricGroup) => {
    selectedAdvancedProductFabricVar({
      ...selectedAdvancedProductFabric,
      fabricGroupObject: fabricGroup,
      combinationFabrics: {
        ...selectedAdvancedProductFabricVar().combinationFabrics,
        [fabricsDrawerState.option.id]: {
          fabricGroupObject: fabricGroup,
          fabricObject: fabric,
          option: fabricsDrawerState.option,
        },
      },
    })
    fabricsDrawerStateVar({
      open: false,
      data: null,
      option: null,
    })
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.fabricsDrawer,
        }}
        anchor="right"
        open={fabricsDrawerState.open}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() =>
          fabricsDrawerStateVar({
            open: false,
            data: null,
            option: null,
          })
        }
      >
        <div className={classes.topDrawerContent}>
          <Typography variant="h5"> {selectorTitle}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() =>
              fabricsDrawerStateVar({
                open: false,
                data: null,
                option: null,
              })
            }
          />
        </div>
        {/* <div className={classes.topDrawerDescription}>
          {' '}
          <Typography variant="caption">{groupProfile?.description}</Typography>
        </div> */}

        <Grid container spacing={2} style={{ padding: 1 }}>
          {fabricsDrawerState.data?.map((fabricGroup, index) => {
            return (
              <>
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <Typography variant="h6">
                    {' '}
                    {
                      getProfileFromLanguage(
                        fabricGroup.fabric_group_profiles,
                        locale,
                      )?.name
                    }
                  </Typography>
                </Grid>
                {fabricGroup.fabrics.map((color, index) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={3}
                      lg={2}
                      key={index + color.code}
                    >
                      <FabricDrawerCard
                        // This is some syntax bug but original id is passed as ID, however it should be product container ID from the top
                        fabricColor={color}
                        fabricGroup={fabricGroup}
                        selected={selectedFabricId === color.id}
                        onClick={onSelectedComponentChange}
                      />
                    </Grid>
                  )
                })}
              </>
            )
          })}
        </Grid>
      </Drawer>
    </>
  )
}
