import { gql } from '@apollo/client'

export const GET_MATCHING_COUPON = gql`
  query GET_MATCHING_COUPON($coupon_code: String!) {
    findUniqueCoupon(where: { coupon_code: $coupon_code }) {
      id
      createdAt
      updatedAt
      fixed_price_coupon
      apply_on_top_of_other_discounts
      coupon_code
      case_sensitive
      expiry_date
      percentage_discount
      fixed_price_discount
      limit
      count_used
      products_that_are_allowed {
        id
      }
    }
  }
`

export const GET_SHOP_SETTINGS_CART_PAGE = gql`
  query GET_SHOP_SETTINGS_CART_PAGE($shopID: Int!) {
    findFirstShopSetting(where: { id: { equals: $shopID } }) {
      id
      current_prices_with_vat
      vat_percentage
    }
  }
`

export const CREATE_CART_PDF = gql`
  mutation CREATE_CART_PDF(
    $customer: CustomerCustomInput!
    $cartItems: [IncomingCartItemCustomInput!]!
    $language: Language!
    $type: CommercialOfferType!
  ) {
    createCommercialOfferBackendPDF(
      customer: $customer
      cartItems: $cartItems
      language: $language
      type: $type
    ) {
      pdf_link
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation UPLOAD_FILE($file: Upload!) {
    uploadFile(file: $file)
  }
`
