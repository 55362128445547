import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'

import { useReactiveVar } from '@apollo/client'
import {
  getPriceFromPriceCategories,
  priceFormatter,
} from '../../../../../utilityFunctions/priceUtils'
import { checkDiscountValidity } from '../../../../../utilityFunctions/discountValidityCheck'
import useWindowDimensions from '../../../Utils/WindowDimensionsHook'
import { Media } from '../../../../../../lib/artsyFresnel/Media'
import {
  getProfileFromLanguage,
  getProfileIndexFromLanguage,
} from '../../../../../utilityFunctions/getProfile'
import {
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTranslation } from 'react-i18next'
import { ProductPrice } from '../../../../UtilityComponents/price'
import { useAppContext } from '../../../../../context/appContext'
import SectionTitle from '../SectionTitle'
import { getValidAdditionalComponents } from './additionalComponentUtils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      sliderContainer: {
        position: 'relative',
        display: 'block',
        width: '100%',
        padding: '0 8%',
        zIndex: 2,
        // height: '500px',
        // maxHeight: '400px',
        // overflow: 'hidden',
        // paddingTop: theme.spacing(6),
        // paddingBottom: theme.spacing(6),
        // marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
          // marginTop: theme.spacing(1),
          // padding: theme.spacing(0, 0, 0, 2),
          padding: '0 15%',
        },

        // '&::after': {
        //   content: '""',
        //   width: '100%',
        //   height: '15%',
        //   position: 'absolute',
        //   right: '0',
        //   bottom: '0',
        //   background:
        //     'linear-gradient(180deg, transparent 0%, rgba(247,249,250,1) 100%)',
        //   zIndex: 2,
        // },

        '& .slick-list': {
          overflow: 'visible',
          overflowX: 'clip',

          [theme.breakpoints.down('md')]: {
            overflowX: 'hidden',
            overflowY: 'visible',
          },
        },

        '& .slick-track': {
          minWidth: '2800px',
        },

        // '& .slick-list ::after': {
        //   content: '""',
        //   width: '100px',
        //   height: '100px',
        //   background: 'red',
        // },
      },

      sliderContainerEnd: {
        '&::after': {
          display: 'none',
        },
      },

      card: {
        // display: 'inline',
        opacity: 0.6,
      },

      cardSelected: {
        opacity: 1,
        border: '5px solid',
        borderColor: theme.palette.secondary.main,
      },

      nextArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        right: '0',
        left: 'auto',
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        marginRight: '-60px',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
        },

        [theme.breakpoints.down('md')]: {
          marginRight: '-50px',
        },
      },
      prevArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        left: '0',
        right: 'auto',

        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        marginLeft: '-60px',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
          transform: 'rotate(-180deg)',
          webkitTransform: 'rotate(-180deg)',
        },

        [theme.breakpoints.down('md')]: {
          marginLeft: '-50px',
        },
      },

      // Price info
      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      //========

      sectionTitle: {
        marginTop: '30px',
        marginLeft: '30px',
        marginRight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        [theme.breakpoints.down('lg')]: {
          marginTop: '10px',
        },
      },
      descriptionSelectionRow: {
        marginTop: '10px',
        marginBottom: '5px',
      },
    }),
  { name: 'MuiAdditionalComponentGroupComponent' },
)

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const AdditionalComponentGroup = ({
  autoplay = false,
  className = null,
  locale,
  componentGroup,
  ...rest
}): JSX.Element => {
  const classes = useStyles() as any
  //=== Crunch data here...
  const [filteredComponents, setFilteredComponents] = useState([])

  const componentGroupProfile = getProfileFromLanguage(
    componentGroup?.additional_component_group_profiles,
    locale,
  )

  let groupTitle
  let groupDescription
  if (componentGroupProfile) {
    groupTitle = componentGroupProfile.name
    groupDescription = componentGroupProfile.description
  } else {
    // We need to set a fallvack
    groupTitle = 'Not translated'
    groupDescription = ''
  }

  const selectedComponents = useReactiveVar(selectedAdditionalComponentsVar)
  useEffect(() => {
    //

    const additionalComponents = getValidAdditionalComponents(
      componentGroup,
      selectedComponents,
    )

    setFilteredComponents(additionalComponents)
  }, [selectedComponents])

  if (!filteredComponents) return null
  return (
    <Grid container justifyContent="space-evenly" spacing={1}>
      <SectionTitle title={groupTitle} />
      <Grid
        container
        justifyContent="space-evenly"
        spacing={0}
        className={classes.descriptionSelectionRow}
      >
        <Grid item lg={12} sx={{ width: '100%' }}>
          <Typography variant="body1" component="p" sx={{ marginLeft: '30px' }}>
            {groupDescription}
          </Typography>

          <Media greaterThan="md">
            <AdditionalComponentSliderDesktop
              additionalComponents={filteredComponents}
              locale={locale}
            />
          </Media>

          <Media lessThan="lg">
            <AdditionalComponentSliderMobile
              additionalComponents={filteredComponents}
              locale={locale}
            />
          </Media>
        </Grid>
      </Grid>
    </Grid>
  )
}

const AdditionalComponentSliderDesktop = ({ additionalComponents, locale }) => {
  const classes = useStyles() as any

  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const applyResize = windowHeight < 900 ? true : false

  // const [currentSlide, setCurrentSlide] = useState(0)
  const [isLastSlide, setIsLastSlide] = useState(false)
  const numberOfSlides =
    additionalComponents.length > 4 ? 4 : additionalComponents.length

  //--- To determine if selected
  const addtionalComponents = useReactiveVar(selectedAdditionalComponentsVar)

  const settings = {
    draggable: false,
    variableWidth: true,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
    // adaptiveHeight: true,
    dots: false,
    infinite: false,
    vertical: false,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,
    beforeChange: (current, next) => {
      if (next === additionalComponents.length - numberOfSlides) {
        setIsLastSlide(true)
      } else {
        setIsLastSlide(false)
      }
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          draggable: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: false,
          variableWidth: false,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: false,
          variableWidth: false,
        },
      },
    ],
  }

  return (
    <Box
      className={clsx(
        isLastSlide && classes.sliderContainerEnd,
        classes.sliderContainer,
      )}
    >
      <Slider {...settings}>
        {additionalComponents.map((additionalComponent, i) => {
          //----
          let isSelected = false
          const indexOfComponent = addtionalComponents.findIndex((object) => {
            return object.id === additionalComponent.id
          })
          if (indexOfComponent > -1) {
            isSelected = true
          }

          return (
            <SwiperSlide
              key={i}
              tag="li"
              style={{ padding: '10px', height: 'auto' }}
            >
              <AdditionalComponentCard
                key={additionalComponent.id}
                additionalComponent={additionalComponent}
                locale={locale}
                selected={isSelected}
              />
            </SwiperSlide>
          )
        })}
      </Slider>
    </Box>
  )
}

const useStylesAdditionalComponentGroupMobile = makeStyles(
  (theme: Theme) =>
    createStyles({
      fabricSelectionRow: {
        marginTop: '10px',
        marginBottom: '5px', // was 50px
      },

      sliderContainer: {
        position: 'relative',
        width: '100vw',
        padding: '0 8%',
        zIndex: 2,
        marginLeft: '10px',
        // marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
          // marginTop: theme.spacing(1),
          // padding: theme.spacing(0, 0, 0, 2),
          // padding: '0 10%',
        },
      },

      // ==== SWIPER SLIDER ======
      slider: {
        display: 'block',
        width: '100%',
        overflow: 'visible',
        // overflowX: 'hidden',
        // background: 'none',
        // touchAction: 'none',
        '--swiper-pagination-color': 'black',
        paddingBottom: '30px',

        '& .swiper-slide': {
          // background: 'none !important',
        },

        '& .swiper-button-prev': {
          padding: '25px',
          // background: '#e7e9ea',
          color: '#000',
          marginLeft: '-50px',
        },
        '& .swiper-button-next': {
          padding: '25px',
          // background: '#e7e9ea',
          color: '#000',
          marginRight: '-50px',
        },
      },
    }),
  { name: 'MuiuseAdditionalComponentGroupMobileComponent' },
)

const AdditionalComponentSliderMobile = ({ additionalComponents, locale }) => {
  const classes = useStylesAdditionalComponentGroupMobile() as any

  //--- To determine if selected
  const selectedAddtionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  const handleSlideChange = (e) => {
    //----
    const activeIndex = e.activeIndex
    const additionalComponent = additionalComponents[activeIndex]
    //   const selectedGroup = filteredFabricGroups[activeIndex]
    //   setSelectedFabricGroup(selectedGroup)
    // console.log('activeIndex :>> ', activeIndex)

    const indexOfGroup = selectedAddtionalComponents.findIndex((object) => {
      return (
        object.additionalComponentGroupId ===
        additionalComponent.additionalComponentGroupId
      )
    })

    if (indexOfGroup > -1) {
      // We we have an existing component from this group, we need to replace
      let newAddtionalComponents = [
        ...JSON.parse(JSON.stringify(selectedAddtionalComponents)),
      ]
      newAddtionalComponents[indexOfGroup] = additionalComponent
      selectedAdditionalComponentsVar(newAddtionalComponents)
    } else {
      // Not yet existing we just add
      let newAddtionalComponents = [
        ...JSON.parse(JSON.stringify(selectedAddtionalComponents)),
        additionalComponent,
      ]
      selectedAdditionalComponentsVar(newAddtionalComponents)
    }
  }

  return (
    <Box className={clsx(classes.sliderContainer)}>
      <Swiper
        id="additionalComponentsSlider"
        modules={[Navigation]}
        tag="section"
        wrapperTag="ul"
        navigation={true}
        autoplay={false}
        loop={false}
        pagination={true}
        freeMode={false}
        cssMode={true}
        slidesPerView={2}
        // spaceBetween={10}
        // initialSlide={0}
        onSlideChange={handleSlideChange}
        className={clsx(classes.slider)}
      >
        {additionalComponents.map((additionalComponent, i) => {
          let isSelected = false
          const indexOfComponent = selectedAddtionalComponents.findIndex(
            (object) => {
              return object.id === additionalComponent.id
            },
          )
          if (indexOfComponent > -1) {
            isSelected = true
          }

          return (
            <SwiperSlide key={i} tag="li">
              <AdditionalComponentCard
                key={additionalComponent.id}
                additionalComponent={additionalComponent}
                locale={locale}
                selected={isSelected}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

const useStylesAdditionalComponent = makeStyles(
  (theme: Theme) =>
    createStyles({
      //---
      example: {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          // padding: '0 15%',
        },
      },

      componentBox: {
        opacity: 1,
        margin: '15px 10px',
        // [theme.breakpoints.down('lg')]: {
        //   paddingBottom: '30px',
        // },
      },

      componentBoxSelected: {
        opacity: 1,
        border: '5px solid',
        borderColor: theme.palette.secondary.main,
        margin: '10px 10px',
        [theme.breakpoints.down('lg')]: {
          // padding: '0 15%',
          border: 'none',
          margin: '15px 10px',
        },
      },
    }),
  { name: 'MuiAdditionalComponent' },
)

const AdditionalComponentCard = ({
  additionalComponent,
  locale,
  selected,
  ...props
}) => {
  //----
  //   console.log('additionalComponent :>> ', additionalComponent)
  //---

  const { t, i18n } = useTranslation(['common'])
  const materialString = t('material')
  const extraPriceString = t('extra-price')
  const { pricesDisabled } = useAppContext()

  const classes = useStylesAdditionalComponent() as any

  const image = additionalComponent.image
    ? additionalComponent.image.src_md ?? additionalComponent.image.src
    : null
  const color = additionalComponent.color
    ? additionalComponent.color.background ?? additionalComponent.color.hex
    : null

  const componentProfile = getProfileFromLanguage(
    additionalComponent.additional_component_profiles,
    locale,
  )

  let componentName
  let componentMaterialName
  let componentDescription

  if (componentProfile != null) {
    componentName = componentProfile.name
    componentDescription = componentProfile.description ?? ''
    componentMaterialName = componentProfile.material_name ?? ''
  } else {
    // We need to set a fallvack
    componentName = 'Not translated'
    componentDescription = ''
    componentMaterialName = ''
  }

  const selectedAddtionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  //====== PRICE CALCULATTIONS ======
  // 1) Component wil either have extra_price
  // 2) Or a PriceFabricCategory - price depending on the selected Fabric.
  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )
  let componentExtraPrice
  if (
    additionalComponent.use_fabric_prices_for_components &&
    additionalComponent.price_fabric_category &&
    additionalComponent.price_fabric_category.length &&
    selectedAdvancedProductFabric?.fabricGroupObject?.form_price_fabric_category
  ) {
    componentExtraPrice = getPriceFromPriceCategories(
      selectedAdvancedProductFabric,
      additionalComponent.price_fabric_category,
    )
  } else {
    componentExtraPrice = additionalComponent.extra_price
  }
  //====== END PRICE CALCULATTIONS  (above) ======

  const onClickHandler = () => {
    //---
    // console.log('Selected an additonal component group')
    // console.log('additionalComponent :>> ', additionalComponent)
    const indexOfGroup = selectedAddtionalComponents.findIndex((object) => {
      return (
        object.additionalComponentGroupId ===
        additionalComponent.additionalComponentGroupId
      )
    })

    if (indexOfGroup > -1) {
      // We we have an existing component from this group, we need to replace
      let newAddtionalComponents = [
        ...JSON.parse(JSON.stringify(selectedAddtionalComponents)),
      ]
      newAddtionalComponents[indexOfGroup] = additionalComponent
      selectedAdditionalComponentsVar(newAddtionalComponents)
    } else {
      // Not yet existing we just add
      let newAddtionalComponents = [
        ...JSON.parse(JSON.stringify(selectedAddtionalComponents)),
        additionalComponent,
      ]
      selectedAdditionalComponentsVar(newAddtionalComponents)
    }
  }

  return (
    <Card
      onClick={onClickHandler}
      className={clsx(
        selected && classes.componentBoxSelected,
        classes.componentBox,
      )}
      sx={{ maxWidth: 600, minWidth: 145, margin: 2 }}
    >
      {image && (
        <CardMedia
          component="img"
          height="140"
          image={image}
          // alt="green iguana"
        />

        // <div
        //   style={{
        //     width: '100%',
        //     minHeight: 200,
        //     background: `url(${image}) no-repeat center center`,
        //     backgroundSize: 'contain',
        //     border: '1px solid #eee',
        //   }}
        // ></div>
      )}
      {!image && color && (
        <div
          style={{
            height: '140px',
            width: '100%',
            background: color,
          }}
        ></div>
      )}
      <Divider></Divider>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div">
              {componentName}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: 'right' }}>
            {selected && <CheckCircleIcon color="success" />}
          </Grid>
        </Grid>
        {componentMaterialName && (
          <Typography variant="body1" color="text.primary">
            {`${materialString} ${componentMaterialName}`}
          </Typography>
        )}
        <Typography variant="body1" color="text.secondary">
          {componentDescription}
        </Typography>
      </CardContent>
      {!pricesDisabled && (
        <CardActions sx={{ float: 'right' }}>
          <Typography variant="body1" color="text.primary">
            {extraPriceString}{' '}
          </Typography>
          <Typography variant="body2" color="text.primary">
            <ProductPrice price={componentExtraPrice} />
          </Typography>
        </CardActions>
      )}
    </Card>
  )
}

const SampleNextArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.nextArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.prevArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

export default AdditionalComponentGroup
